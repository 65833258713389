<template>
  <div>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="3" class="">
            <label>Mostrar</label>
            <v-select v-model="tableSettings.perPage" :options="perPageOptions" :clearable="false"
              class="per-page-selector d-inline-block mx-50" />
            <label>registros</label>
          </b-col>
          <b-col cols="12" md="9">
            <div class="d-flex align-items-center justify-content-end">
              <b-col md="4" class="offset-8">
                <b-input-group size="sm">
                  <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Buscar" />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">
                      Borrar
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-button variant="primary" class="btn-icon rounded-circle mr-1" @click="newCotization('store')"
                v-if="permissions.includes('cotization.store')">
                <feather-icon icon="PlusIcon" />
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table ref="refRoleListTable" class="position-relative" :items="dataTable" responsive :fields="tableColumns"
        primary-key="id" :sort-by.sync="tableSettings.sortBy" show-empty empty-text="No se encontraron datos"
        :sort-desc.sync="tableSettings.sortDesc" :filter="filter" :filter-included-fields="filterOn"
        @filtered="onFiltered">
        <template #cell(status)="data">
          <div class="text-nowrap">
            <b-form-select v-model="data.item.status" :options="status"
              @input="changeStatusCotization(data.item.id, data.item.status)" />
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button class="btn-icon rounded-circle" @click="editCotization(data.item.id, 'cotization')"
            v-b-tooltip.hover.v-primary title="Editar" variant="warning" v-if="permissions.includes('cotization.edit')">
            <feather-icon icon="EditIcon" />
          </b-button>

          <!-- <form :action="'https://axis-backend.test/generate-pdf-cotization/' + data.item.id" method="get" target="_blank"
            rel="noopener noreferrer" style="display: contents;"> -->
          <!-- <form :action="'https://axis.api.desarrollo.com.co/generate-pdf-cotization/' + data.item.id" method="get"
            target="_blank" rel="noopener noreferrer" style="display: contents;">
            <input type="hidden" name="token" :value="csrf" />
            <b-button variant="danger" class="btn-icon rounded-circle" type="submit"
              v-if="permissions.includes('cotization.generate_pdf_cotization')" v-b-tooltip.hover.v-primary
              title="Generar Pdf">
              <feather-icon icon="FileIcon" />
            </b-button>
          </form> -->

          <b-button variant="danger" class="btn-icon rounded-circle" v-b-tooltip.hover.v-primary
            @click="modalVoucher.status = true, modalVoucher.voucher_id = data.item.id"
            title="Generar Pdf Voucher">
            <feather-icon icon="ClipboardIcon" />
          </b-button>

          <!-- <b-button variant="success" class="btn-icon rounded-circle" @click="historic()" v-b-tooltip.hover.v-primary
            title="Historico De Cambios">
            <feather-icon icon="BookOpenIcon" />
          </b-button> -->

          <b-button variant="danger" class="btn-icon rounded-circle" @click="deleteAgency(data.item)"
            v-if="permissions.includes('cotization.delete')" v-b-tooltip.hover.v-primary title="Eliminar">
            <feather-icon icon="Trash2Icon" />
          </b-button>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Viendo del {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }}
              registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

            <b-pagination v-model="tableSettings.page" :total-rows="totalRows" :per-page="tableSettings.perPage"
              first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card>

    <b-modal centered v-model="modalVoucher.status" title="Selecciona una opción">
      <div class="mx-2 my-2">
        <b-button @click="openFirstURL" variant="primary">Voucher en español</b-button>
        <b-button class="float-right" @click="openSecondURL" variant="info">Voucher en ingles</b-button>
      </div>

      <template #modal-footer="{ ok, cancel, hide }">
        <b-button variant="danger" @click="cancel()">
          Cancelar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip, BFormGroup, BFormSelect, BInputGroup,
  BInputGroupAppend
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'cotizationHistory',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    VBTooltip,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend
  },
  data() {
    return {
      modalVoucher: {
        status: false,
        voucher_id: null
      },
      filter: null,
      filterOn: [],
      csrf: "Bearer " + localStorage.access_token,
      permissions: [],
      form: {
        changeStatus: null
      },
      shallShowEmailComposeModal: false,
      formDisabled: false,
      isAddNewRoleSidebarActive: false,
      refRoleListTable: null,
      perPageOptions: [25, 50, 100],
      searchQuery: '',
      inputs: [
        { value: 'id', title: 'No. Cotización' },
        { value: 'first_name', title: 'Nombre' },
        { value: 'last_name', title: 'Apellido' },
        { value: 'email', title: 'Correo' },
        { value: 'phone', title: 'Teléfono' },
        { value: 'status', title: 'Estado' },
      ],
      typeSearch: [
        { value: 'LIKE', title: 'Igual' },
        { value: 'NOT LIKE', title: 'No es igual' },
        { value: '>', title: 'Mayor que' },
        { value: '<', title: 'Menor que' }
      ],
      tableColumns: [
        { key: 'id', label: 'No. Cotización', sortable: true },
        { key: 'firstName', label: 'Nombre', sortable: true },
        { key: 'lastName', label: 'Apellido', sortable: true },
        { key: 'email', label: 'Correo' },
        { key: 'phone', label: 'Teléfono' },
        { key: 'status', label: 'Estado', sortable: true },
        { key: 'actions', label: 'acciones' },
      ],
      sortBy: 'id',
      isSortDirDesc: true,
      totalRows: 0,
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
      dataTable: [],
      showLoadingTable: false,
      tableSettings: {
        filter: this.$route.params && this.$route.params.id,
        searchQuery: '',
        input: '',
        typeSearch: '',
        perPage: 25,
        page: 1,
        sortBy: 'id',
        sortDesc: true,
      },
      formDataEdit: {},
      status: [
        { value: 1, text: 'cotizado' },
        { value: 2, text: 'vencida' },
        { value: 3, text: 'emitida' }
      ]
    }
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  watch: {
    "tableSettings.sortBy": {
      handler(val) {
        this.fetchData()
      },
    },
    "tableSettings.sortDesc": {
      handler(val) {
        this.fetchData()
      },
    },
    "tableSettings.perPage": {
      handler(val) {
        this.fetchData()
      },
    },
    "tableSettings.searchQuery": {
      handler(val) {
        this.fetchData()
      },
    },
    "tableSettings.page": {
      handler(val) {
        this.fetchData()
      },
    },
  },
  created() {
    this.fetchData()
    this.userData = JSON.parse(localStorage.getItem('userData'))
    this.permissions = this.userData.permissions
  },
  methods: {
    openFirstURL() {
      var token = 'Bearer ' + localStorage.getItem('access_token')
      window.open('https://axis.api.desarrollo.com.co/generate-pdf-cotization/' + this.modalVoucher.voucher_id + '/1?token=' + token, '_blank');
      this.modalVoucher.status = false;
      this.modalVoucher.voucher_id = null;
    },
    openSecondURL() {
      var token = 'Bearer ' + localStorage.getItem('access_token')
      window.open('https://axis.api.desarrollo.com.co/generate-pdf-cotization/' + this.modalVoucher.voucher_id + '/2?token=' + token, '_blank');
      this.modalVoucher.status = false;
      this.modalVoucher.voucher_id = null;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    historic() {
      this.$router.push('/historic');
    },
    fetchData() {
      this.showLoadingTable = true
      this.$http.get('/cotization/list', { params: this.tableSettings }).then((response) => {
        this.dataTable = response.data.cotizations
        this.totalRows = response.data.total
        this.dataMetaCounter()
        this.showLoadingTable = false
      })
    },
    changeStatusCotization(idCotization, idStatus) {
      this.$http.post('/cotization/changeStatus/' + idCotization, { 'status': idStatus })
        .then((response) => {
          if (response.data.code == 200) {
            this.$swal({
              icon: "success",
              title: response.data.message
            });
            this.fetchData()
          }
          if (response.data.code == 500) {
            this.$swal({
              icon: "error",
              title: response.data.message
            });
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    generatePdf(id) {
      this.$http.post('/cotization/generate-pdf-cotization/' + id, this.formDisabled, { responseType: 'blob' })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'cotizacion.pdf'); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.$swal({
            icon: "success",
            title: "Éxito",
            html: "Archivo descargado éxitosamente!",
          });
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    generatePdfVaucher(id) {
      this.$http.post('/cotization/generatePdfVaucher/' + id, this.formDisabled, { responseType: 'blob' })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'vaucher.pdf'); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.$swal({
            icon: "success",
            title: "Éxito",
            html: "Archivo descargado éxitosamente!",
          });
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    dataMetaCounter() {
      const localItemsCount = this.dataTable.length
      this.dataMeta.from = this.tableSettings.perPage * (this.tableSettings.page - 1) + (localItemsCount ? 1 : 0)
      this.dataMeta.to = this.tableSettings.perPage * (this.tableSettings.page - 1) + localItemsCount
      this.dataMeta.of = this.totalRows
    },
    detailRole(role) {
      this.formDisabled = true
      this.formDataEdit = role
      this.isAddNewRoleSidebarActive = true
    },
    editRole(role) {
      this.formDataEdit = role
      this.isAddNewRoleSidebarActive = true
    },
    deleteAgency(item) {
      this.$swal({
        title: `Esta séguro de eliminar la agencia ${item.name}?`,
        text: "Este proceso no se puede revertir!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, Eliminar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.post('/cotization/delete/' + item.id).then(() => {
            this.fetchData()
            this.$swal({
              icon: 'success',
              title: 'Agencia eliminado!',
              text: 'El rol ha sido eliminado.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelado',
            text: 'Eliminación cancelada',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    statusAgency(status) {
      return status == 1 ? 'Activo' : status == 2 ? 'Emitido' : status == 3 ? 'Anulado' : 'Indefinido'
    },
    editCotization(id, cotization) {
      this.formDataEdit = id
      this.$router.push({
        name: 'new-cotization',
        params: { id: id, cotization: cotization }
      });
    },
    newCotization(store) {
      this.$router.push('/cotizations/new-cotization', { params: store });
    },
    searchData() {
      this.fetchData()
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
